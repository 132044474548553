/* You can add global styles to this file, and also import other style files */


@import 'ngx-toastr/toastr';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

/*-----------------------------------------------
    Basic CSS
-----------------------------------------------*/
*,
::before,
::after,
:focus {
    border: none;
    outline: none;
    text-decoration: none !important;
    font-family: "Ubuntu", sans-serif;
}

.mdc-list-item__primary-text {
    font-family: "Ubuntu", sans-serif !important;
    font-size: 14px !important;
}

html,
body {
    background-color: #eee !important;
    color: var(--text-color);
    min-height: 100vh;
    height: 100vh;
}

button,
li,
a {
    text-decoration: none;
}

button,
a {
    cursor: pointer
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    font-family: "Ubuntu", sans-serif !important;
}

.container-fluid {
    margin: 0;
    padding: 0;
}

.mat-form-field-underline {
    display: none
}

.sidenav-content {
    height: calc(100vh - 100px) !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #575DF0;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #575DF0;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #ffffff;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #5FD068;
}

.mat-slide-toggle-bar {
    background-color: #AAAAAA;
}

.ps__rail-x.ps--clicking .ps__thumb-x,
.ps__rail-x:focus>.ps__thumb-x,
.ps__rail-x:hover>.ps__thumb-x {
    height: 5px !important;
}

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
    opacity: 0 !important;
}

.ps--focus>.ps__rail-x,
.ps--focus>.ps__rail-y,
.ps--scrolling-x>.ps__rail-x,
.ps--scrolling-y>.ps__rail-y,
.ps:hover>.ps__rail-x,
.ps:hover>.ps__rail-y {
    opacity: 0;
    background-color: #fff !important;
}

.mat-error {
    margin-bottom: 5px !important;
}

.mat-error .reduce-bottom-space.err-msg {
    font-size: 12px !important;
    white-space: nowrap;
}

.mat-error .reduce-bottom-space {
    white-space: nowrap;
}

.mat-error.same-address-err {
    color: #f44336 !important;
    margin-top: -15px;
}

.addon-msg {
    margin-top: -15px !important;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
    z-index: 99999 !important;
}

/*-----------------------------------------------
    Login / Reset Password / Forgot Password
-----------------------------------------------*/

.section-side-login-box {
    background-image: url(./assets/image/Login-page-img.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center
}

.section-side-reset-box {
    background-image: url(./assets/image/login-img.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center
}

.section-side-changepswd-box {
    background-image: url(./assets/image/changepassword-img.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center
}

.section-side-sherpa-box {
    background-image: url(./assets/image/Login-page-img.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center
}

.section-side-forgotpswd-box {
    background-image: url(./assets/image/login-img.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center
}

.section-box {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.section-box .mat-input-element {
    caret-color: #1A2421 !important;
    margin-top: 2px;
}

.login-color {
    background-color: white;
}

.reset-color {
    background-color: white;
}

.change-color {
    background-color: white;
}

.change-color .mat-input-element:-webkit-autofill,
.change-color .mat-input-element:-webkit-autofill:hover,
.change-color .mat-input-element:-webkit-autofill:focus,
.change-color ::placeholder {
    color: #999E9C !important;
}

.forgotpswd-color ::placeholder {
    color: #999E9C !important;
}

.forgotpswd-color {
    background-color: white;
}

.form-box .section-left-header {
    display: flex;
    justify-content: left;
    margin-bottom: 40px;
}

.form-box .form-footer .form-signin-link {
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
    color: #8A8A8A;
    margin-top: 20px;
}

.form-box .form-footer .form-signin-link.login {
    color: #0693E3;
}

.form-box .form-header .form-title {
    font-size: calc(34px + (32 - 30) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 300;
    color: #161616;
    margin: 8px 0px;
    font-family: 'Ubuntu', sans-serif;
}

.form-box .form-header .form-subtitle {
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 30px;
    margin-top: 15px;
}

.form-box .form-header .subtitle-forgotpswd-color {
    color: #8A8A8A;
}

.form-box .form-header .subtitle-resetpswd-color {
    color: #8A8A8A
}

.form-box .form-footer {
    text-align: center;
    margin-top: -15px;
}

.form-box .section-content-inner {
    margin: 32px 0;
}

.form-box .edit-form-container .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0.45em !important;
}

.section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.section-box .btn {
    font-size: calc(15px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
    background-color: #01C3A7;
    color: #000000;
    width: 400px;
    height: 48px;
    border: none;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin: 25px 0 15px 0;
}

.section-box .btn:hover,
.section-box .btn:focus {
    box-shadow: none;
}

.form-box {
    width: 400px;
    height: auto;
}

.form-box .mat-form-field-prefix .mat-icon,
.form .mat-form-field-suffix .mat-icon {
    color: #000000;
}

.form-box .link-box {
    display: flex;
    justify-content: center;
    gap: 15px
}

.form-box .form-footer .link {
    font-size: calc(14px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
    color: #5B5B5B;
    line-height: 42px;
}

.form-box .link-box .link:hover {
    text-decoration: underline;
}

.form-box .mat-form-field-wrapper {
    padding-bottom: 1.25em;
    width: 400px;
}

.form-box.inner-form-box .mat-form-field-wrapper {
    padding-bottom: 1.25em;
    width: 400px;
    margin: 5px 0px 15px 0px !important;
}

.form-box .mat-form-field-infix {
    border-top: none;
}

.form-box .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0.875em 0 0.875em 0;
}

.form-box .mat-form-field-appearance-outline .mat-form-field-outline {
    color: #E2E2E2;
}

.form-box .mat-form-field-appearance-outline .mat-form-field-outline-thick:not(.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick) {
    color: #01C3A7 !important
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick:not(.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick) {
    color: #01C3A7 !important
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 0px 20px;
    align-items: center;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #BBBDC3;
}

.form-box .mat-input-element {
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #1A2421 !important;
}

.form-box .mat-form-field-underline {
    width: 0;
}

.form-box .form-field {
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300))) !important;
}

.form-box .form-content {
    display: flex;
    flex-direction: column;
}

.form-box .mat-input-element::placeholder {
    color: #999E9C;
}

.form-box .mat-select-placeholder {
    color: #A1A1A1;
}

.logo-sherpa {
    width: 137px;
    height: 50px;
}

.top-head {
    font-size: 34px !important;
    font-weight: 300 !important;
    color: #161616;
    white-space: nowrap;
}

.top-head.top-heading {
    color: #000000 !important;
    font-weight: lighter !important;
    letter-spacing: 0px;
    line-height: 42px;
}

.top-text {
    font-size: 16px;
    color: #8A8A8A;
    font-weight: 500;
    margin-top: 10px !important;
    line-height: 24px;
}

.store-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em 3em 1em 3em;
    background-color: #F6F6F6;
    border-radius: 8px;
    margin-top: 2em;
    line-height: 22px;
    font-size: 16px;
    color: #383838;
    font-weight: 400;
}

.policy-link {
    font-size: calc(14px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
    color: #5B5B5B;
}

.policy-link:hover {
    color: #5B5B5B;
}

@media screen and (max-width: 575px) {
    .form-box {
        width: 100%;
        padding: 0px 20px
    }

    .form-box .mat-form-field,
    .form-box .mat-form-field .mat-form-field-wrapper {
        width: 100%;
    }

    .form-box .form-footer .btn {
        width: 100%
    }
}

/* ======================================= */

input {
    font-family: 'Ubuntu', sans-serif !important;
    background-color: transparent;
}

input[type="file"]:focus,
input[type="checkbox"]:focus,
input[type="radio"]:focus {
    outline: none
}

button {
    transition: .5s;
    background-size: 200% auto;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    background-color: transparent
}

button:hover {
    background-position: right center
}

a {
    text-decoration: none !important
}

a:not([href]):not([tabindex]) {
    text-decoration: none
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 !important
}

ul li {
    list-style-type: none
}

table {
    width: 100%
}

input:-webkit-autofill,
input:-internal-autofill-selected {
    background-color: transparent !important;
    box-shadow: 0 0 0 1000px white inset;
    font-size: 16px !important
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
}

input[type=number] {
    -moz-appearance: textfield
}

.mat-option {
    height: 2.5em !important;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}

@media screen and (max-width:1199px) {
    .w-xl-100 {
        width: 100%
    }
}

/* content styles starts */
.mat-drawer-container {
    background-color: #eee;
    color: #000
}

/* content styles ends */

/* header styles starts */
.header-wrapper {
    display: flex;
    justify-content: space-between
}

.header-nav {
    display: flex;
    margin-bottom: 0;
    align-items: center
}

.header-nav li a {
    color: #7643a9;
    font-size: 13px;
    font-weight: 600;
    margin: 0 0 0 1rem
}

.admin-title {
    font-size: 16px;
    color: #000000;
    font-weight: 500;
}

.profile-logo {
    margin-right: 8px;
}

.main-heading {
    font-size: 24px;
    color: #383838;
    font-weight: 500 !important;
    margin-bottom: 1.5em !important;
}

.main-heading.bank-details {
    margin-bottom: 0 !important;
}

.logout {
    margin-right: 1rem !important;
    font-size: 16px;
    font-weight: 400;
}

.disabled-link, .disabled-link span {
    background-color: transparent;
    cursor: not-allowed !important;
    color: #A0A0A0 !important;
}

@media screen and (max-width: 767px) {
    .header-nav {
        display: none
    }
}

/* header styles ends */

/* admin panel styles starts */
mat-sidenav.mat-drawer.mat-sidenav.sidenav.ng-tns-c83-0.ng-trigger.ng-trigger-transform.mat-drawer-side.mat-drawer-opened.mat-sidenav-fixed.ng-star-inserted {
    box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
}

.sidenav {
    width: 270px;
    border-right: 0 !important;
    background: #fff;
    border: none
}

.sidenav .mat-list-base {
    margin: 2rem 0 0 0
}

.sidenav .mat-toolbar {
    justify-content: center;
    padding: 1rem 0;
    background: inherit
}

.sidenav .mat-toolbar,
.menu-list-ul {
    padding: 2rem 7rem 2rem 2rem
}

.menu-list-item .mat-list-item-content {
    display: flex;
    align-items: center;
    margin-top: 0px
}

.menu-list-item .material-icons-outlined {
    margin-right: .7rem
}

.menu-items .menu-list-item .mat-icon {
    font-size: 24px;
    float: left;
    margin-right: 5px;
    margin-left: 5px;
    line-height: 26px;
    width: 30px;
    text-align: center;
    color: #A0A0A0;
}

.menu-items .menu-list-item .mat-icon.active-link {
    font-size: 24px;
    float: left;
    margin-right: 5px;
    margin-left: 5px;
    line-height: 26px;
    width: 30px;
    text-align: center;
    color: #383838 !important;
}

.menu-items .mat-list-base .mat-mdc-list-item,
.mat-list-base .mat-list-option {
    width: auto !important
}

.menu-items .mat-mdc-list-item {
    margin-left: 12px;
    margin-top: 10px;
    color: #2784fd;
    padding-left: 5px !important;
}

.menu-items .mat-mdc-list-item.active-link {
    color: #383838 !important;
    background: #01c3a7 !important;
    border-radius: 4px;
}

.mat-list-base .mat-mdc-list-item,
.mat-list-base .mat-list-option {
    width: auto !important
}

.layouts-container.mat-toolbar-single-row {
    justify-content: end;
    height: 64px;
    background-color: #eee;
    border-bottom: 1px solid lightgray;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.calculators.layouts-container.mat-toolbar-single-row {
    width: 100%;
    height: 72px
}

.sidenav-expand-menu {
    margin: 0px 12px !important;
    padding: 0 16px !important
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
    background: rgb(255 255 255) !important;
    background-color: transparent !important;
}

.sidenav-expand-menu-icon {
    margin-right: 6px !important;
    font-size: 20px;
    display: flex;
    align-items: center
}

.admin-content-wrapper {
    padding: 1.625rem;
    background: #fff;
    min-height: calc(100vh - 100px);
    background-color: #eee;
    position: relative
}

.admin-content-wrapper .breadcrumb a {
    font-size: 13px;
    font-weight: 500;
    color: rgba(0, 0, 0, .5)
}

.admin-heading {
    font-size: calc(24px + (28 - 24) * ((100vw - 300px) / (1600 - 300)));
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0rem;
    font-weight: 500
}

.main-title {
    font-size: calc(26px + (30 - 26) * ((100vw - 300px) / (1600 - 300)));
    color: #0F172A
}

.main-container {
    padding: 0 1rem 1rem 1rem
}

.icon-state svg path {
    fill: black
}

.admin-container .card {
    border: 0;
    border-radius: 12px;
    box-shadow: 0 0 20px #59667a1a
}

.admin-container .card-body {
    padding: 1.563rem;
}

.admin-container .card-content {
    padding: 1rem 0 0;
    display: flex;
    flex-direction: column
}

.admin-container .material-icons-outlined {
    font-size: 30px;
    height: 45px;
    width: 45px;
    color: #2784fd;
    background: rgb(39 132 253 / 15%);
    border-radius: 10px;
    font-variation-settings: 'wght' 100, 'GRAD' 200, 'opsz' 48;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-container .card-title {
    font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0
}

.admin-details {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 13px;
    margin: .5rem 0 0 0;
    display: flex;
    align-items: center;
}

.admin-details,
.admin-details:hover {
    color: #999999
}

.admin-details .material-icons {
    margin: 0 .2rem 0 0
}

.admin-image,
.admin-image .image {
    width: 100%;
    height: 300px
}

.mat-menu-panel {
    min-width: 120px !important;
    margin-left: 1rem;
}

.mat-mdc-menu-item-text {
    font-size: 14px !important;
}

.mat-mdc-menu-item .mat-icon {
    margin-right: 9px !important
}

.mat-mdc-menu-item .material-icons,
.admin-button-wrapper .material-icons-outlined {
    font-size: 1.25rem;
    height: 1.25rem;
    width: 1.25rem
}

.admin-button-wrapper img {
    cursor: pointer;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 1px solid #ffffff;
    margin-right: 10px;
}

@media screen and (max-width:575px) {
    .admin-content-wrapper .breadcrumb {
        position: relative;
        top: 0;
        left: 0
    }
}

@media screen and (max-width:991px) {
    .layouts-container.mat-toolbar-single-row {
        justify-content: space-between
    }
}

/* admin panel styles ends */


/* Side nav css Start */
.logo-div {
    display: flex;
    justify-content: center;
}

.logo-menu {
    width: 120px;
    top: 10px;
    position: absolute;
    left: 0
}

.my-nav-btn {
    position: relative;
    top: 20px;
    left: 15px
}

.example-spacer {
    flex: 1 1 auto
}

.sidenav-container {
    height: 100%
}

.mat-toolbar.mat-primary {
    position: sticky;
    top: 0;
    z-index: 1
}

.toolbar {
    background: #fff;
    color: #000;
    height: 25px;
    position: sticky
}

.sidebar-list {
    padding: 3rem 40px 0;
    display: flex;
    justify-content: center;
    flex-direction: column
}

.sidebar-list-link {
    padding: 20px 0
}

.a-link-back {
    padding: 18px 0;
    color: #4f4f4f;
    border-radius: 100px;
    font-weight: 500;
    font-size: calc(18px + (18 - 18) * ((100vw - 300px) / (1600 - 300))) !important
}

.link-icon {
    padding-right: 10px;
    width: 40px;
    font-size: 35px;
    color: #d3d4d8
}

.active-link {
    font-weight: 700
}

.link-icon:hover {
    color: #fff !important
}

.a-link-back:hover {
    font-weight: 700 !important;
    background: 0 0
}

.a-link-back:active {
    font-weight: 700 !important
}

@media only screen and (max-width:1499px) {
    .sidebar-list {
        padding: 90px 25px 0 45px
    }
}

@media only screen and (max-width:960px) {
    .toolbar {
        background: #fff;
        display: block;
        color: #000;
        height: 25px;
        position: sticky
    }

    .sidebar-list {
        padding: 0 25px 0 25px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center
    }
}

.grid-container {
    margin: 50px
}

.dashboard-card {
    width: 100%;
    height: auto
}

.dash-card-size {
    display: flex
}

.dashboard-top-content-underline {
    margin: 0 20px 0 50px
}

.search-icon {
    position: absolute;
    top: 4px;
    right: 15px;
    width: 30px
}

.link-icon-1 {
    font-size: 25px;
    color: #d3d4d8
}

.profile {
    display: flex;
    align-items: center
}

.profile-img {
    width: 55%;
    border-radius: 100px;
    background: #387537;
    padding: 12px 10em 12px 20px
}

.John_name {
    position: absolute;
    padding-left: 75px
}

.John {
    color: #fdfdfd;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px
}

.icon-custom {
    width: 25px
}

.more-button {
    position: absolute;
    top: 5px;
    right: 10px
}

.dashboard-card-content {
    text-align: center
}

.mat-h1 {
    margin-top: 50px
}

.dashboard-top-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    width: 90%;
    margin: auto;
    padding-bottom: 5px
}

.example-full-width {
    width: 45%;
    border-radius: 50px;
    border: 1px solid #ddd
}

.user_icon {
    width: 40px;
    height: 40px
}

.bell_icon {
    width: 30px;
    height: 30px;
    margin-right: 15px
}

.notication_icon {
    width: 28px;
    height: 28px;
    margin-right: 15px
}

.login-profile {
    height: 44px;
    width: 44px;
    background: #5580e9;
    border-radius: 150px;
    display: flex;
    align-items: center;
    justify-content: center
}

.login-profile-text {
    font-size: 24px;
    color: #fff;
    font-weight: 600
}

.item3 {
    display: flex;
    justify-content: center;
    align-items: center
}

footer {
    text-align: center;
    color: #b2b2b2
}

.sidenav .top-logo {
    padding: 15px 0px;
    margin: 0;
    display: block;
    position: relative;
    z-index: 4;
}

.sidenav .top-logo .simple-text {
    text-transform: uppercase;
    padding: 10px 0px;
    font-size: 18px;
    color: #3C4858;
    white-space: nowrap;
    font-weight: 400;
    line-height: 30px;
    overflow: hidden;
    text-align: center;
    display: block;
}

.sidenav .logo-img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 30px;
}

.sidenav .logo-img .logo {
    width: 137px;
    height: 50px;
    position: absolute;
    top: 12px;
}

a.mat-mdc-list-item span {
    color: #383838;
    font-weight: 500;
}

.heading {
    color: #383838;
    font-weight: 500 !important;
    font-size: 24px !important;
    margin-top: 1rem !important;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: none;
}

.mat-expansion-panel-header.mat-expanded,
.mat-expansion-panel-header.mat-expanded:hover {
    background: #01C3A7 !important;
    height: 48px;
    border-radius: 4px 0px 0px 4px;
    -webkit-border-radius: 4px 0px 0px 4px;
    -moz-border-radius: 4px 0px 0px 4px;
    -ms-border-radius: 4px 0px 0px 4px;
    -o-border-radius: 4px 0px 0px 4px;
}

.mat-expansion-panel-header {
    padding: 0px 30px 0px 24px !important;
    margin-top: 10px;
}

.menu-list-icon {
    margin-right: 10px;
}

.mat-expansion-panel-spacing {
    margin: 0 !important;
    background-color: transparent;
}

.sidenav-menu mat-panel-title.mat-expansion-panel-header-title {
    font-size: 16px;
    color: #383838;
    font-weight: 500;
}

.mat-expansion-panel-body {
    padding: 10px 24px 0px 58px !important;
}

.border-left {
    border-left: 2px solid red !important;
}

.border-left.active-link {
    border-left: 2px solid #01C3A7 !important;
}

#default-sidebar {
    background-image: url(./assets/image/sidebar-bg-img.png);
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
}

.mat-accordion .mat-expansion-panel:not(.mat-expanded),
.mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
    background-color: transparent;
}

@media only screen and (max-width:900px) {
    .example-full-width {
        width: 90%;
        border-radius: 50px;
        border: 1px solid #ddd;
        margin-bottom: 10px;
        display: none
    }

    .dashboard-top-content {
        justify-content: flex-end
    }
}

@media only screen and (max-width:650px) {
    .profile-img {
        width: 94%
    }

    .grid-container {
        margin: 0
    }

    .notication_icon {
        display: none
    }

    .bell_icon {
        display: none
    }
}

/*side nav Css end  */

/* footer styles starts */
.footer {
    background-color: transparent
}

.socicon {
    height: 25px
}

.footer-link-wrapper {
    display: flex;
    justify-content: space-between;
}

.footer-menu,
.footer-social {
    display: flex;
    margin: 0;
    padding: 0
}

.footer-menu li {
    margin: 0 1rem 0 0
}

.footer-social li {
    margin: 0 0 0 1rem
}

.footer-link {
    font-size: calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 700;
    color: #252821
}

.footer-link:hover {
    color: #252821;
    opacity: .7
}

.footer-purple {
    background-color: #2784fd;
}

.footer-menu-space-between {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    gap: 10px;
    justify-content: center;
}

.footer-menu-space-between li a {
    color: #A1A1A1;
    font-size: 14px;
    font-weight: 400;
}

.footer-menu-space-between li p {
    color: #A1A1A1;
    font-size: 14px;
    font-weight: 400;
}

.admin-footer {
    background-color: #ffffff
}

.admin-footer-text {
    font-size: 13px;
    font-weight: 500;
    color: #58666e;
    padding: .8rem .625rem;
    margin: 0
}

@media screen and (max-width:767px) {
    .footer-link-wrapper {
        justify-content: flex-end
    }

    .footer-menu {
        display: none
    }
}

/* footer styles ends */
.new-proj {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background-color: #2784fd !important;
    padding: 0px 10px 0px 5px;
    color: #ffffff;
    max-height: 32px;
    gap: 5px
}

.project-button .material-icons-outlined {
    background-color: transparent;
    color: #FFFFFF;
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    font-size: 26px;
}

/*-----------------------------------------------
    Admin-service-management
-----------------------------------------------*/
.temp {
    width: 50px;
    height: 30px;
}

.btns-grp {
    display: flex;
    gap: 10px;
    align-items: center;
}

.edit {
    color: #a9afbb;
    font-size: 20px;
    cursor: pointer;
}

th.mat-header-cell {
    font-size: 1rem;
    color: #434343;
}

.table-content td.mat-cell,
td.mat-footer-cell {
    color: #000000;
}

.filter-value {
    font-size: 14px;
}

.btn-white {
    background-color: #fff;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    -o-border-radius: 50% !important;
}

.filter-value.mat-form-field-appearance-fill .mat-form-field-flex {
    border-bottom: 1px solid !important;
    background-color: transparent !important;
    padding-left: 0 !important;
}

.custom_date .mat-icon {
    display: flex !important
}

/*-----------------------------------------------
    Admin Dashboard
-----------------------------------------------*/
.main-content {
    padding-top: 1rem;
}

.card {
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    border: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    border-radius: 6px;
    color: #333333;
    background: #fff;
    width: 100%;
}

.card-stats .card-header.card-header-icon,
.card-stats .card-header.card-header-text {
    text-align: right;
}

.card [class*=card-header-] {
    margin: 0px 15px 0;
    padding: 0;
    position: relative;
}

.card [class*=card-header-],
.card[class*=bg-] {
    color: #fff;
}

.card .card-header {
    border-bottom: none;
    background: transparent;
    z-index: 3 !important;
}

.card [class*=card-header-] .card-icon,
.card [class*=card-header-] .card-text {
    border-radius: 3px;
    background-color: #999999;
    padding: 15px;
    margin-top: -20px;
    margin-right: 15px;
    float: left;
}

.card .card-header-warning .card-icon,
.card .card-header-warning .card-text,
.card .card-header-warning:not(.card-header-icon):not(.card-header-text),
.card.bg-warning,
.card.card-rotate.bg-warning .front,
.card.card-rotate.bg-warning .back {
    background: linear-gradient(60deg, #FFA06A, #FFA06A);
}

.card-stats .card-header.card-header-icon i {
    font-size: 36px;
    line-height: 56px;
    width: 56px;
    height: 56px;
    text-align: center;
}

.card-stats .card-header.card-header-icon em {
    font-size: 36px;
    line-height: 56px;
    width: 56px;
    height: 56px;
    text-align: center;
}

.card-stats .card-header .card-category:not([class*=text-]) {
    color: #5B5B5B;
    font-size: 18px;
}

.card-stats .card-header.card-header-icon .card-title,
.card-stats .card-header.card-header-text .card-title,
.card-stats .card-header.card-header-icon .card-category,
.card-stats .card-header.card-header-text .card-category {
    margin: 0;
}

.card-stats .card-header .card-icon+.card-category {
    padding-top: 10px;
}

.card .card-header.card-header-icon .card-title,
.card .card-header.card-header-text .card-title {
    margin-top: 15px;
    color: #5B5B5B;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}

.card-stats .card-header+.card-footer {
    border-top: 1px solid #eee;
    margin-top: 14px;
}

.card .card-footer {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
}

.card .card-body+.card-footer,
.card .card-footer {
    padding: 0;
    padding-top: 10px;
    margin: 0 15px 10px;
    border-radius: 0;
    justify-content: space-between;
    align-items: center;
}

.card .card-body+.card-footer .stats,
.card .card-footer .stats {
    color: #8A8A8A;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
}

.card .card-footer .author,
.card .card-footer .stats {
    display: inline-flex;
}

.card .card-body+.card-footer .stats .material-icons,
.card .card-footer .stats .material-icons {
    position: relative;
    top: 4px;
    font-size: 16px;
}

.card .card-footer .stats .material-icons {
    position: relative;
    top: 2px;
    margin-right: 3px;
    margin-left: 3px;
    font-size: 18px;
}

.text-danger {
    color: #f44336 !important;
}

.card .card-header-success .card-icon,
.card .card-header-success .card-text,
.card .card-header-success:not(.card-header-icon):not(.card-header-text),
.card.bg-success,
.card.card-rotate.bg-success .front,
.card.card-rotate.bg-success .back {
    background: linear-gradient(60deg, #01c3a7, #01c3a7);
}

.card .card-header-danger .card-icon,
.card .card-header-danger .card-text,
.card .card-header-danger:not(.card-header-icon):not(.card-header-text),
.card.bg-danger,
.card.card-rotate.bg-danger .front,
.card.card-rotate.bg-danger .back {
    background: linear-gradient(60deg, #ef5350, #e53935);
}

/*-----------------------------------------------
    Create-verified-sherpa-account
-----------------------------------------------*/
.form-box.sherpa-box .mat-form-field-wrapper {
    padding-bottom: 1.25em;
    width: 100%;
}

.form-box .sherpa-form-field {
    width: 100%;
}

.section-content .mat-select .mat-select-arrow-wrapper {
    display: none;
}

.section-content .mat-select-placeholder {
    color: #A1A1A1;
    font-size: 16px;
}

.section-content .checkbox-text {
    font-size: 16px;
    color: #000000;
    margin-left: 5px;
}

.section-content .sherpa-labels {
    font-size: 14px;
    color: #383838;
    font-weight: 400;
}

.mat-checkbox-inner-container {
    margin-top: 4px !important;
}

/*-----------------------------------------------
    create-Service-modal
-----------------------------------------------*/
.admin-content-wrapper .custom-bread-crumb {
    padding: 0 !important;
}

.create-service-modal-content {
    margin: auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.create-service-content {
    margin: 0 auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 50%;
    transform: translateY(50%);
}

.create-service-details {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 12rem);
}

.create-service-details .add-service {
    background-color: #01C3A7;
    border-radius: 8px;
    padding: 16px 69px;
    margin-top: 40px;
    color: #000000;
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
}

.create-service-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.create-modal-content {
    margin-top: 1.5rem;
    width: 50%;
}

.create-service-modal-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0 0 0;
}

.create-service-modal-button button {
    background-color: #01C3A7;
    border-radius: 8px;
    font-size: 0.875;
    font-family: "Ubuntu", sans-serif;
    border: none;
    padding: 1rem 3.875rem;
    font-weight: 500;
}

.create-service-modal-content .modal-header {
    padding: 1.5rem 0rem 1rem;
    border-bottom: 0.5px solid #A1A1A1;
}

.create-service-modal-content .modal-header .warning-msg {
    color: #0693E3
}

.create-service-modal-content .modal-title {
    margin: 0;
    font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-family: "Ubuntu", sans-serif;
    color: #000;
    font-weight: 500;
}

.create-modal-content .form-group {
    display: flex;
    flex-direction: column;
}

.create-modal-content .form-group label {
    font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
    font-family: "Ubuntu", sans-serif;
    color: #383838;
    white-space: pre;
}

.create-modal-content .fees-section {
    display: flex;
    justify-content: space-between;
    padding: 0rem 0 0.75rem 0;
}

.create-modal-content .grand-total-section {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0 0.79rem 0;
}

.create-modal-content .total-section {
    border-bottom: 1px solid #707070;
    padding-top: 0.4rem;
}

.create-modal-content .footer-content {
    border-top: 1px solid #D4D4D4;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin: 0;
}

.create-service-modal-content .duration-section {
    border: 1px solid #D4D4D4;
    margin-bottom: 1rem;
    width: 100%;
}

.create-modal-content .time-slot-label {
    background-color: #F6F6F6;
    border-radius: 20px;
    padding: 0.5rem;
    margin: 0rem 0 1.25rem 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.create-modal-content .time-slot-label .material-icons {
    font-size: 1.25rem;
    display: inline-flex;
    align-items: center;
    color: #383838;
    cursor: pointer;
}

.create-modal-content .edit-button {
    color: #0693e3 !important;
    margin-right: .25rem;
}

.datepicker-content .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0 0 0.2rem 0.5rem !important;
}

.datepicker-content .mat-datepicker-toggle-default-icon {
    margin-top: -5px;
}

.money-icon {
    background-color: #01C3A7;
    border-radius: 8px;
    padding: 0.7rem 0.7rem .5rem;
    left: -10px;
    position: absolute;
    top: -7px;
    color: #fff;
}

.form-input-container {
    display: flex;
    justify-content: space-between;
}

.matTooltip {
    color: #0693E3 !important;
    border: 1px solid #0693E3;
    padding: 0.25rem .5rem;
    background-color: #fff;
}

.form-input-container input {
    padding-left: 2.5rem;
}

.create-modal-content .delete-button {
    color: #383838;
}

.create-modal-content .add-button {
    background-color: #0693E3;
    color: #fff;
    font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.create-modal-content .cancel-button {
    color: #383838;
    font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    margin-left: - .5rem;
    margin-bottom: 0.5rem;
}

.create-modal-content .mat-select-arrow-wrapper {
    height: 0px !important
}

.create-modal-content .form-group .mat-select-placeholder {
    color: #A1A1A1;
}

.create-modal-content .tooltip-button .mat-icon {
    font-size: 1.5rem;
    color: #999E9C;
    margin: 0.25rem 0.625rem;
}

.create-modal-content .mat-select-arrow {
    display: none;
}

.create-modal-content .expand-icon {
    margin-top: -.15rem;
}

.dropdown-icon {
    height: 14px !important;
    width: 14px;
    color: #A1A1A1;
}

.service-create-button {
    margin-top: 1.25rem;
    padding: 0.625rem;
    background-color: #01C3A7;
    border-radius: 8px;
    color: #000000;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
}

.create-modal-content .mat-form-field-appearance-outline .mat-form-field-outline {
    border-radius: 7px;
    background-color: #ffff;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}

.create-modal-content .service-field .mat-form-field-wrapper {
    padding-bottom: 1.2em !important;
}

.create-modal-content .row .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.6rem 0.7rem 1rem 0.45rem;
    display: flex;
}

.mat-icon.right-arrow {
    color: #8A8A8A;
    width: 14px;
    font-size: 14px;
}

.main-heading.bank-details.back-service {
    color: #8A8A8A;
    font-size: 14px;
    cursor: pointer;
}

.create-modal-content .footer-content.edit-content {
    display: initial;
}

.slots-time {
    padding-left: 8px;
}

button:disabled {
    background-color: #01C3A7;
    opacity: 0.65;
    color: #000000;
    cursor: not-allowed;
}

.mat-tooltip {
    color: #0693E3 !important;
    border-radius: 4px;
    margin: 14px;
    max-width: 250px;
    padding-left: 8px;
    padding-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: scale(0);
    border: 2px solid #0693E3;
    font-size: 12px;
    background-color: #FFFFFF;
}

/*-----------------------------------------------
    Edit Service Component
-----------------------------------------------*/
.edit-service-btn {
    margin-top: 1.25rem;
    padding: 0.625rem;
    border-radius: 8px;
    color: #000000;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
}

.create-modal-content .disable-field.mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: #00000013;
    cursor: not-allowed !important;
}

.create-modal-content .mat-select-disabled .mat-select-value {
    color: #000000;
    cursor: not-allowed;
}

.not-allow {
    cursor: not-allowed;
}

@media screen and (max-width:1200px) {
    .create-modal-content {
        width: 100%;
    }
}

@media screen and (max-width:575px) {
    .create-service-modal-content .modal-header {
        display: block;
    }

    .create-service-modal-button button {
        padding: 1rem 1.5rem;
    }

    .create-modal-content .cancel-button,
    .add-button {
        margin-bottom: 0;
    }

    .create-modal-content .cancel-button {
        margin-left: 0rem;
    }

    .create-service-details .add-service-img {
        width: 100%;
    }

    .create-service-modal-content .modal-header .warning-msg {
        margin-top: .625rem;
    }

    .create-modal-content .time-slot-label {
        display: block;
    }
}

/*-----------------------------------------------
    Sales/Earning
-----------------------------------------------*/
.page-wrapper .apply {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #01C3A7;
    letter-spacing: 1px;
    height: 32px;
    width: 100%;
    border-radius: 4px;
    padding: 6px 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    line-height: 1px;
}

.page-wrapper .table-header-row {
    background-color: #E2E2E2;
}

.page-wrapper table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    overflow: hidden;
}

.page-wrapper th.mat-header-cell {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
}

.page-wrapper .table-content td.mat-cell,
td.mat-footer-cell {
    color: #434343;
    font-size: 16px;
    font-weight: 400;
}

.page-wrapper .table-content .mat-form-field-wrapper {
    padding-bottom: 0;
}

.page-wrapper .table-content .mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 4px 4px 0 0;
    padding: 0 0.75em 0 0.75em;
    height: 32px;
    width: 140px;
}

.page-wrapper .table-content .text-muted1 {
    color: #383838;
    font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
}

.page-wrapper .date-range-selection {
    gap: 20px
}

.page-wrapper .mat-select-arrow-wrapper {
    display: contents;
}

.page-wrapper .mat-select-value,
.mat-select-placeholder {
    color: #383838;
}

.cal-btn {
    margin-left: 7px !important;
}

@media screen and (max-width:576px) {
    .sales-date-picker {
        width: 100%;
    }

    .page-wrapper .table-content .mat-form-field-appearance-fill .mat-form-field-flex {
        width: 100%;
    }
}

/*-----------------------------------------------
    Verified-User-Dashboard
-----------------------------------------------*/
.verified-dashboard .card {
    box-shadow: none;
    border-radius: 8px;
    margin: 0
}

.verified-dashboard .card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
}

.verified-dashboard .card-icon {
    color: #01C3A7;
    font-size: 38px;
    height: 38px;
    width: 38px;
    margin: 0 10px 0 0;
}

.verified-dashboard .card-text {
    color: #5B5B5B;
    font-size: calc(22px + (28 - 22) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 300;
}

.verified-dashboard .card-value {
    color: #01C3A7;
    font-size: calc(22px + (28 - 22) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
    margin: 0;
}

.verified-dashboard .table {
    width: 100%;
    min-width: 700px;
    border-radius: 8px;
    overflow: hidden !important;
    margin-bottom: 0;
}

.verified-dashboard .table thead {
    background: #E2E2E2;
}

.verified-dashboard .table>:not(:first-child) {
    border-top: 0;
}

.verified-dashboard .mat-header-row,
.verified-dashboard .mat-row {
    vertical-align: middle;
    height: 56px;
}

.verified-dashboard .mat-header-cell {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
}

.verified-dashboard .mat-cell {
    color: #5B5B5B;
    font-size: 16px;
    font-weight: 400;
}

.status {
    font-size: 14px;
    font-weight: 400;
    padding: 4px 12px;
    border-radius: 20px;
}

.status.checked {
    background-color: #0693E3;
    color: #F6F6F6;
}

.status.started {
    background-color: #01C3A7;
    color: #383838;
}

.status.upcoming {
    background-color: #FFA06A;
    color: #383838;
    border: 1px solid #FFA06A;
}

.status.cancelled {
    background-color: #F95F5C;
    color: #F6F6F6;
    border: 1px solid #F95F5C;
}

.step-wrapper {
    background-color: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 8px;
    padding: 17px 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.step-wrapper:hover {
    border: 2px solid #01C3A7;
}

.step-img-wrapper {
    display: flex;
    align-items: center;
}

.step-name {
    color: #161616;
    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
    padding-left: 8px;
}

.step-id {
    color: #8A8A8A;
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
    border: 1px solid #8A8A8A;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    text-align: center;
    vertical-align: middle;
}

.more-details {
    color: #a1a1a1
}

.section-content .sherpa-form-field.disable-field.mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: #00000013;
    cursor: not-allowed !important;
}

@media screen and (max-width:1400px) {
    .verified-dashboard .card {
        margin: 0px 0px 10px 0px;
    }
}

/*-----------------------------------------------
    Manage-Service
-----------------------------------------------*/
.manage-service .table {
    width: 100%;
    min-width: 700px;
    border-radius: 8px;
    overflow: hidden !important;
    margin-bottom: 0;
}

.manage-service .table thead {
    background: #E2E2E2;
}

.manage-service .table>:not(:first-child) {
    border-top: 0;
}

.manage-service .mat-header-row,
.manage-service .mat-row {
    vertical-align: middle;
    height: 56px;
}

.manage-service .mat-header-cell {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    white-space: pre;
    padding: 5px;
}

.manage-service .mat-cell {
    color: #5B5B5B;
    font-size: 16px;
    font-weight: 400;
    padding: 5px;
    white-space: pre;
}

.manage-service .data-center {
    text-align: center;
}

.add-service-btn .add-service {
    background-color: #01C3A7;
    border-radius: 8px;
    padding: 16px 30px;
    color: #000000;
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
}

.send-msg-btn {
    background-color: #01C3A7;
    padding: 5px;
    border-radius: 8px;
    width: 48px;
    height: 48px;
    margin-left: -5px;
}

.msg-field input {
    border: 2.5px solid #E2E2E2;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    padding-left: 10px;
    /*padding-right: 10px;*/
}

.message-data {
    width: 100%;
    height: auto;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 1rem;
}

.message-modal-content {
    height: 350px;
    overflow: auto;
    margin-top: 10px;
}

.receive-text {
    padding: 10px;
    background-color: #F1F1F1;
    width: fit-content;
    border-radius: 6px;
    color: #383838;
    font-size: 16px;
    word-break: break-word;
}

.sent-text {
    padding: 10px;
    background-color: #01C3A7;
    width: fit-content;
    border-radius: 6px;
    color: #000000;
    font-size: 16px;
    float: right;
    word-break: break-word;
}

.footer-data {
    padding: 0px 0px 24px 24px;
}

.user-text {
    background-color: #F6F6F6;
    height: 42px;
    display: flex;
    align-items: center;
    color: #161616;
    font-weight: 400;
    font-size: 16px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.modal-title {
    font-size: 20px !important;
    color: #000000;
    font-weight: 400 !important;
}

.manage-service-menu.mat-menu-panel {
    min-height: 0px !important;
}

/*-----------------------------------------------
    Service-history
-----------------------------------------------*/
.status.finished {
    background-color: #FFA06A;
    color: #383838;
}

.status.canceled {
    background-color: #F95F5C;
    color: #F6F6F6;
}

.tab-grp {
    background-color: #FFFFFF;
    width: fit-content;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.nav-pills .nav-link {
    color: #000000;
    font-weight: 500;
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.manage-service-filter input {
    background-color: #FFFFFF;
    border: 1px solid #BBBDC3;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-image: url("./assets/image/search-icon.png");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 5px 6px;
    padding: 0px 0px 0px 30px;
    height: 32px;
    width: 240px;
}

.manage-service-filter input::placeholder {
    color: #A1A1A1;
}

.manage-service .action-msg-box {
    margin-top: -15px;
}

@media screen and (max-width:481px) {
    .msg-field input {
        width: 100%;
    }

    .send-msg-btn {
        width: 100%;
        margin-left: -15px;
    }
}

/*-----------------------------------------------
    Verified-User-Bank-Details
-----------------------------------------------*/
input:focus,
input:hover {
    outline: 0;
}

.verified-bank-details {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 12rem);
}

.verified-bank-details .add-bank {
    background-color: #01C3A7;
    border-radius: 8px;
    padding: 16px 69px;
    margin-top: 40px;
    color: #000000;
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
}

.add-bank-details-form .modal-dialog {
    max-width: 660px;
}

.add-bank-details-form .modal-content {
    padding: 0 40px;
    border-radius: 8px;
    border: 0;
}

.add-bank-details-form .modal-header {
    border-bottom: 0;
    padding: 24px 0 24px;
}

.add-bank-details-form .modal-title {
    color: #000000;
    font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
}

.add-bank-details-form .modal-body {
    padding: 0
}

.form-field {
    display: flex;
    flex-direction: column;
}

.form-field .mat-form-field-wrapper {
    margin: 0 !important;
    padding-bottom: 20px;
}

.form-field .mat-form-field-infix {
    border-top: 0.3em solid transparent;
}

.form-field .mat-select-arrow-wrapper {
    background-image: url(./assets/image/down-arrow.svg);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow,
.form-field .mat-select-arrow {
    color: #fff;
}

.form-field .form-label {
    color: #383838;
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
    margin-bottom: 4px;
    opacity: 1;
}

.add-bank-details-form .submit {
    background-color: #01C3A7;
    border-radius: 8px;
    padding: 16px 0;
    margin: 15px 0 40px;
    color: #000000;
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 600;
    width: 100%;
    letter-spacing: 1px;
}

.close-btn {
    margin-right: -30px;
    margin-top: -15px;
}

.add-bank-details-form .mat-form-field-appearance-outline .mat-form-field-outline {
    color: #E2E2E2;
}

.verified-bank-details-card .card {
    box-shadow: none;
    border-radius: 8px;
}

.verified-bank-details-card .card>* {
    padding: 24px !important;
}

.verified-bank-details-card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0693E3;
    color: #fff;
    border-radius: 8px 8px 0px 0px;
}

.verified-bank-details-card .card-title {
    color: #FFFFFF;
    font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
    margin-bottom: 0;
}

.verified-bank-details-card .mat-menu-trigger {
    padding: 0;
    width: 20px;
    height: 20px;
    line-height: 20px;
}

.account-number {
    color: #5B5B5B;
    font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
    letter-spacing: 8px;
}

.verified-bank-details-card .card-footer {
    margin: 0 !important;
    padding-right: 0 !important;
    border-top: 1px solid #E2E2E2;
}

.verified-bank-details-card .account-type {
    color: #8A8A8A;
    font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
}

.verified-bank-details-card .primary {
    color: #000000;
    font-size: 12px;
    background-color: #FFA06A;
    border-radius: 11px 0px 0px 11px;
    padding: 0.5px 8px;
}

.mat-menu-before {
    width: 190px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px !important;
    border: 1px solid #0693E3;
}

.delete-bank-modal .modal-dialog {
    max-width: 400px;
}

.delete-bank-modal .modal-content {
    border-radius: 8px;
    border: 0;
}

.delete-bank-modal .modal-header {
    padding: 24px 24px 12px;
    border-bottom: 0;
}

.delete-bank-modal .modal-title {
    color: #000000;
    font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
}

.delete-bank-modal .modal-body {
    padding: 0 24px 24px;
}

.delete-bank-modal .confirmation-text {
    color: #5B5B5B;
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
}

.delete-bank-modal .modal-footer {
    padding: 12px;
    border-top: 0;
    background-color: #F6F6F6;
}

.delete-bank-modal .cancel {
    color: #000000;
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
    background-color: #00D08400;
    padding: 10px 20px;
    margin: 0;
    letter-spacing: 1.4px;
}

.delete-bank-modal .delete {
    color: #FFFFFF;
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
    background-color: #F95F5C;
    border-radius: 8px;
    padding: 10px 20px;
    margin: 0;
    letter-spacing: 1.4px;
}

.bank-acc-type.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
    color: transparent;
}

.cancel-popup {
    font-size: 40px;
    margin-top: -40px;
    margin-right: -10px;
}

.default-bank {
    border: 1px solid #01C3A7;
    color: #01C3A7;
    padding: 2px 8px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

/* .mat-checkbox-inner-container.bank-check-box {margin-bottom: 0px!important;} */
/*---------------------------------------------
    Sherpa-Profile
-----------------------------------------------*/
.sherpa-profile .profile-container {
    margin: auto;
}

.sherpa-profile .profile-container .row-container {
    display: flex;
    justify-content: center;
}

.sherpa-profile .profile-container .btn-container {
    display: flex;
    justify-content: flex-end;
}

.sherpa-profile .profile-container .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    display: none;
}

.sherpa-profile .profile-container .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: #ffffff;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}

.sherpa-profile .profile-container .discart-btn {
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    color: #000000;
    margin-right: 20px;
}

.sherpa-profile .profile-container .save-btn {
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    background-color: #01C3A7;
    border: none;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    color: #000000;
    padding: 2px 24px;
}

.sherpa-profile .image-style {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    height: auto;
    width: 100%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.5em 0 1em 0;
}

.sherpa-profile .mat-icon {
    color: #383838;
}

.hover-image {
    cursor: pointer;
}

.sherpa-profile .disable-field.mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: #00000013;
    cursor: not-allowed !important;
}

.img-box-2 {
    height: 120px;
    width: 120px;
    margin-top: 5px;
    cursor: pointer;
}

.img-box-2:hover {
    cursor: pointer;
    background-image: url("./assets/image/photo_camera.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px;
    position: relative;
}

.support-container {
    width: 580px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.support-container .save-btn {
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    background-color: #01C3A7;
    border: none;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    color: #000000;
    padding: 2px 120px;
    margin-top: 35px;
    font-weight: 600;
    letter-spacing: 1px;
}

.support-container .mat-mdc-text-field-wrapper {
    background-color: #ffffff;
}
.mat-mdc-form-field-error {
    display: contents !important;
}

.support-container .support-heading {
    color: #5B5B5B;
    text-align: center;
    font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
    margin-bottom: 12px;
    line-height: 24px;
}

.support-container .support-text {
    color: #5B5B5B;
    text-align: center;
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
    margin-bottom: 40px;
}

.support-container .mat-form-field-wrapper {
    padding-bottom: 3.2rem !important;
}

.support-container textarea {
    resize: none !important;
}

.profile-image-container-size {
    height: 120px;
    width: 120px;
    border: 2px solid #FFFFFF;
    margin-top: 5px;
    cursor: pointer;
}

.sherpa-profile .profile-image-container .mat-icon {
    color: #000;
    position: absolute;
    left: 49px;
    top: 54px;
    display: block;
    z-index: 1;
}

.profile-image-container-size:hover {
    background-color: #000;
    opacity: 60%;
}

/*---------------------------------------------
    Upload Profile Image
-----------------------------------------------*/
.profile-image-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.profile-image-container {
    margin-bottom: 40px;
    margin-top: -5em;
    position: relative;
}

.profile-modal .modal-dialog {
    max-height: auto;
    margin: 1.7rem auto;
}

.profile-modal .modal-body {
    padding: 20px 30px;
}

.profile-modal .modal-body .profile-upload-size-info {
    font-size: calc(10px + (12 - 10) * ((100vw - 300px) / (1600 - 300)));
    padding-top: 4px;
}

.profile-modal .modal-body .profile-image-container {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}

.profile-modal .modal-header {
    padding: 25px 0;
    border: none !important;
    display: flex;
    justify-content: center;
}

.profile-modal .modal-header .modal-title {
    font-size: calc(28px + (30 - 28) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
    color: #000000;
}

.profile-modal .modal-body .modal-text {
    font-size: calc(11px + (13 - 11) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
    color: #8D929B;
    margin-bottom: 0;
    text-align: center;
}

.profile-modal .modal-body .modal-text.top-space {
    margin-top: 30px;
}

.profile-modal .modal-footer {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    border: none !important;
}

.profile-modal .modal-body .form .profile-image-upload {
    display: flex;
    flex-direction: column;
    padding: 0 28px;
    cursor: pointer;
}

.profile-modal .modal-footer .discart-btn {
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
    color: #000000;
    padding: 12px 24px;
    font-weight: 500;
}

.profile-modal .modal-footer .save-btn {
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
    background-color: #01C3A7;
    border: none;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    color: #000000;
    padding: 12px 24px;
    font-weight: 500;
}

.image-upload {
    height: 100px;
    width: 100px;
    border-radius: 50px;
    border: 1px dashed #575DF0;
    color: #575DF0;
    background-color: #F3F3F3;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.edit-profile-container .profile-image-container-size {
    height: 100px;
    width: 100px;
    border: 1px dashed #575DF0;
    margin-top: 5px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
    margin-bottom: 10px;
}

@media screen and (max-width:575px) {
    .img-responsive {
        width: 220px;
    }
}

@media screen and (max-width:767px) {
    .support-container {
        width: 100%;
    }
}

@media screen and (max-width:991px) {
    .sherpa-profile .image-container {
        display: none;
    }

    .profile-image-container {
        margin-top: 0;
    }
}

/*-----------------------------------------------
    Edit Admin Profile
-----------------------------------------------*/
.profile-edit .upload-image {
    margin: 30px 0 20px 0;
}

.edit-profile-container .edit-form-container .admin-labels {
    color: #868686;
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
    line-height: 19px;
    font-weight: 400;
    opacity: 1;
}

.edit-profile-container .image-upload {
    height: 100px;
    width: 100px;
    border-radius: 50px;
    border: 1px dashed #575DF0;
    color: #575DF0;
    background-color: #F3F3F3;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.edit-profile-container .edit-form-container .mat-form-field {
    width: 100%
}

.edit-profile-container .edit-form-container .mat-form-field .mat-form-field-underline {
    display: none
}

.edit-profile-container .edit-form-container .mat-form-field .mat-form-field-wrapper {
    padding-bottom: 0px;
    padding-top: 5px;
    border-radius: 8px;
}

.edit-profile-container .edit-form-container .mat-form-field .mat-form-field-flex {
    padding-top: 0px;
    border-radius: 8px;
    align-items: center;
}

.edit-profile-container .edit-form-container .mat-form-field .mat-form-field-infix {
    padding: 0.4em 1.5em 0.75em 0 !important;
}

.edit-profile-container .edit-form-container .admin-info-heading {
    font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
    color: #333333;
    line-height: 24px;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 15px
}

.edit-profile-container .edit-form-container .edit-admin-footer .edit-admin-button .create-user {
    background-color: #01C3A7;
    border-radius: 8px;
    color: #000000;
    padding: 10px 22px;
}

.edit-profile-container .edit-form-container .edit-admin-footer .edit-admin-button .cancel {
    color: #000000;
    padding: 9px 24px;
}

.edit-profile-container .edit-form-container .mat-select .mat-select-arrow-wrapper {
    display: none;
}

.edit-profile-container .edit-form-container .mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
    padding: 0;
    display: flex;
    justify-content: flex-end;
}

.edit-profile-container .edit-form-container .mat-error.reduce-bottom-padding {
    padding-bottom: 0;
    padding-top: 2px;
}

.edit-profile-container .edit-form-container .mat-error.increase-z-index {
    z-index: 10;
}

.edit-profile-container .edit-form-container .mat-error.error-top-spacing {
    margin-top: 2px;
}

.edit-profile-container .edit-form-container .drop-down .mat-form-field-invalid .mat-form-field-flex {
    border: 1px solid #f44336;
    background-image: none;
}

.edit-profile-container .edit-form-container .drop-down .mat-form-field.ng-valid .mat-form-field-flex {
    border: 1px solid #575DF0;
    background-image: none;
}

.edit-profile-container .edit-form-container .optional-class .mat-form-field.ng-valid .mat-form-field-flex {
    border: none;
    background-image: none;
}

.edit-profile-container .profile-image-container {
    display: flex;
    flex-direction: column;
}

.create-user-color {
    color: #575DF0;
}

.hide-data {
    display: none;
}

.edit-admin-admin-info .mat-error {
    margin-top: 14px;
    margin-left: -5px;
}

.edit-profile-container {
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    margin: auto;
}

.edit-admin-admin-info .col-12 {
    margin-top: 20px;
}

.edit-form-container .disable-field.mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: #00000013;
    cursor: not-allowed !important;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}

/*-----------------------------------------------
    Breadcrumb
-----------------------------------------------*/
.custom-bread-crumb {
    margin-bottom: 0;
}

.custom-bread-crumb a {
    color: #8A8A8A !important;
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
}

.custom-bread-crumb .line {
    color: #8A8A8A !important;
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
    padding: 0 5px !important;
}

/*-----------------------------------------------
    Toaster Alert
-----------------------------------------------*/
.toast-container .ngx-toastr {
    width: 415px !important;
    border-radius: 5px !important;
    box-shadow: 4px 7px 8px #0000001A !important;
    background-position-x: 15px !important;
    background-position-y: 15px !important;
}

.toast-success {
    border-radius: 5px !important;
    background-color: #14C38E;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
}

.toast-error {
    border-radius: 5px !important;
    background-color: #EB5353;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
}

.toast-title {
    color: #ffffff !important;
    font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300))) !important;
}

.toast-message {
    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300))) !important;
    color: #ffffff !important;
}

.toast-success .toast-progress {
    background-color: green !important;
}

.toast-error .toast-progress {
    background-color: #be013e !important;
}

.toast-success .toast-progress,
.toast-warning .toast-progress,
.toast-error .toast-progress,
.toast-info .toast-progress {
    opacity: 1 !important;
    height: 2px !important;
}

@media screen and (max-width:575px) {
    .toast-container .ngx-toastr {
        width: 280px !important;
    }
}

/*-----------------------------------------------
    Pagination 
-----------------------------------------------*/
.ngb-pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.custom-pagination .page-link:hover {
    border: 1px solid #dee2e6;
}

@media screen and (max-width:576px) {
    .ngb-pagination {
        display: block;
        text-align: right;
    }
}

.custom-select {
    background-color: #F2F4F6;
    padding-left: 10px;
    color: #0F172A;
}

.select-height {
    height: 40px;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #01C3A7;
    border-color: #01C3A7;
}

.page-link {
    color: #717682;
}

/* For stripe card css */
form.checkout {
    max-width: 500px;
    margin: 2rem auto;
    text-align: center;
    border: 2px solid #eee;
    border-radius: 8px;
    padding: 1rem 2rem;
    background: white;
    font-family: monospace;
    color: #525252;
    font-size: 1.1rem;
}

form.checkout button {
    padding: 0.5rem 1rem;
    color: white;
    background: coral;
    border: none;
    border-radius: 4px;
    margin-top: 1rem;
}

form.checkout button:active {
    background: rgb(165, 76, 43);
}

.table-responsive .ng-scroll-content {
    display: flex;
}

.payment-btn {
    background-color: #01C3A7;
    border-radius: 30px;
    padding: 4px 8px;
}

/* Common responsive CSS Start */
@media screen and (max-width:576px) {
    .table-responsive .ng-scroll-content {
        display: flex;
    }

    .admin-content-wrapper {
        padding: 1rem;
    }

    .verified-dashboard .card-text {
        font-size: 1rem;
    }

    .main-heading {
        margin-bottom: 1em !important;
    }

    .main-heading.admin-inner-heading {
        margin-bottom: 0em !important;
    }

    .layouts-container.mat-toolbar-single-row {
        padding: 0;
    }

    .add-bank-details-form .modal-content {
        padding: 0 1rem
    }

    .add-bank-details-form .modal-header {
        padding: 1rem 0 1.24rem;
    }

    .add-bank-details-form .submit {
        margin: 1rem 0 1rem;
    }

    .verified-bank-details .add-bank-img {
        width: 100%;
    }

    .add-service-btn .add-service {
        padding: .75rem 1rem;
    }

    .add-bank-header {
        display: block !important;
    }

    .verified-bank-details .add-bank {
        padding: .75rem 2rem;
    }

    .admin-content-wrapper .tab-content {
        flex-direction: column;
        align-items: flex-start !important
    }

    .manage-service-filter {
        width: 100%;
        margin-top: 1rem;
    }

    .manage-service-filter.admin-filter {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .manage-service-filter input {
        width: 100%;
    }

    .support-container .save-btn {
        margin-top: 0;
    }
}

@media screen and (max-width:1199px) {

    .login-page-content .form-box .mat-form-field-wrapper,
    .login-page-content .form-box .section-content-inner,
    .section-header,
    .login-page-content .form-footer {
        padding: .5rem 1rem;
    }

    .section-box .btn {
        width: 100%;
    }

    .sales-date-picker {
        display: flex;
        flex-direction: column;
    }
}

/* Common responsive CSS End */

/*-----------------------------------------------
    View User Management 
-----------------------------------------------*/

.mat-menu-content:not(:empty) {
    padding: 0 !important;
}

.admin-select-value .mat-select-value-text {
    color: #000000 !important;
}

/* Logout */
.delete-profile-modal-body {
    padding: 24px;
}

.delete-profile-modal-body .delete-text {
    font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 400;
    color: #5B5B5B;
}

.delete-profile-modal-body h4 {
    color: #000;
    font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
    padding-bottom: 20px;
}

.delete-form-modal-footer .done-btn {
    background-color: #F95F5C;
    border-radius: 4px;
    padding: 10px 20px;
    text-align: center;
    font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
    color: #FFFFFF;
}

.delete-form-modal-footer .cancel-btn {
    background-color: #fff;
    border-radius: 4px;
    padding: 10px 20px;
    text-align: center;
    font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1600 - 300)));
    color: #000;
    margin-left: 10px;
    font-weight: 500;
}

.modal-dialog {

    .mat-select-arrow-wrapper {
        display: none;
    }

    .dropdown-icon {
        height: 24px !important;
    }

    .mat-icon-button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: -7px;
    }

    .mat-select-placeholder {
        opacity: 0.5;
    }
    .mat-icon {
        display: block !important;
      }
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background: #01C3A7 !important;
}

.border-b {
    border-bottom: 1px solid;
}

.border-r {
    border-right: 1px solid;
}

.border-y{
    border-top: 1px solid;
    border-bottom: 1px solid;
}